import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { connect } from "react-redux";
import {
  compose,
  lifecycle,
  withHandlers,
  withProps,
  withState,
} from "recompose";
import { autofill, change, reduxForm, resetSection, touch } from "redux-form";

import { withAppUserPreferences, withNotifier } from "@dpdgroupuk/mydpd-app";
import { SHIPMENT_TYPES } from "@dpdgroupuk/mydpd-enums";
import { Banner, withOverlay, withPrompt } from "@dpdgroupuk/mydpd-ui";

import {
  DELIVERY_DETAILS_SEARCH_FORM,
  Fields,
  INBOUND_CONSIGNMENT,
  INVOICE,
  OUTBOUND_CONSIGNMENT,
  OutboundServicesRelatedFields,
  PRODUCT_DESCRIPTIONS_FORM,
  ProductDescriptionsEntity,
  ShipmentEntity,
} from "~/constants/forms";
import { SHOW_ALERT_DISPLAY_TIME } from "~/constants/snackbar";
import * as S from "~/constants/strings";
import withOnBlur from "~/hocs/withOnBlur";
import withPostcodeIssue from "~/hocs/withPostcodeIssue";
import withPrint from "~/hocs/withPrint";
import withServices from "~/hocs/withServices";
import {
  AddressModels,
  ServiceModels,
  SettingsModels,
  ShipmentModels,
} from "~/models";
import { getServicesForUser } from "~/models/service/service";
import postcodeValidation from "~/models/validators/additionalPostcodeValidation";
import {
  countryValidation,
  invoiceExporterUkimsValidation,
  invoiceImporterCountryValidation,
  invoiceImporterEoriValidation,
  invoiceImporterUkimsValidation,
  outboundTotalWeightValidation,
  parcelCountryValidation,
  parcelValidation,
  returnTotalWeightValidation,
  submissionErrorFirst,
  validateShipmentDate,
} from "~/models/validators/additionalValidations";
import { shipmentSchema } from "~/models/validators/shipmentSchema";
import { ShipmentActions, ShipmentSelectors } from "~/pages/Shipment/redux";
import { getPackageContentOrder } from "~/pages/Shipment/view";
import { ReferenceActions } from "~/redux";
import createValidator from "~/utils/joiReduxForm";
import { getDeepKeys, getValue } from "~/utils/object";
import { initializeForm } from "~/utils/reduxForm";
import { formatMessage } from "~/utils/string";

import withAddressBookAutocomplete from "./withAddressBookAutocomplete";
import withClearData from "./withClearData";
import withConnect from "./withConnect";
import withCreateShipmentAnalytics from "./withCreateShipmentAnalytics";
import withProfileChange from "./withProfileChange";
import withShipmentDate from "./withShipmentDate";
import withPostcodeAutocomplete from "./withShipmentPostcodeAutocomplete";
import { omit } from "lodash";
import {
  clearCustoms,
  setDefaultCurrency,
} from "~/pages/Shipment/redux/actions";

export default compose(
  connect((state, props) => ({
    form: props.pageConfig.formName,
    uiFields: ShipmentSelectors.getRegisteredFields(state, props.pageConfig),
    isEditProductFormActive: ShipmentSelectors.isEditProductFormActive(state),
  })),
  withAppUserPreferences,
  withNotifier,
  Banner.withBanner,
  withPrompt,
  withPrint,
  withOverlay,
  withOnBlur,
  withConnect,
  withProfileChange,
  withProps(({ selectedService }) => ({
    onClickPackageDetailsHelp: () => {}, // TODO: Add click logic
    onClickSaveToAddressBook: () => {}, // TODO: Add click logic
    selectedServiceCode: ServiceModels.getServiceCode(selectedService),
    selectedNetworkCode: ServiceModels.getNetworkCode(selectedService),
  })),
  withState("prevPostcodeIssue", "setPrevPostcodeIssue"),
  withState("inboundServiceErrorBannerId", "setInboundServiceErrorBannerId"),
  withState("outboundServiceErrorBannerId", "setOutboundServiceErrorBannerId"),
  withState("consignmentBannerId", "setConsignmentBannerId"),
  withState("parcelsErrorsBannerId", "setParcelsErrorsBannerId"),
  withServices,
  reduxForm({
    shouldError: () => true,
    onChange: (currentValues, dispatch, props, prevValues) => {
      /**
         save actual prevValues
         **/
      props.setPrevValues(prevValues);

      /**
         clear services queries when form was dropped to initial state
         **/

      if (props.pristine) {
        props.clearServicesQuery();
      }

      /**
         check if field was blurred
         **/
      if (
        !currentValues.activeField &&
        currentValues.activeField !== prevValues.activeField
      ) {
        /**
           - get actual prevValues
           - on blur callback, fires fetching inbound/outbound services
           **/
        const prev = props.getPrevValues();

        props.onBlurServiceRelatedField(currentValues, prev, props);
      }
    },
    validate: (values, props) =>
      createValidator(shipmentSchema(props), [
        ...(props.shipmentAdditionalValidation
          ? props.shipmentAdditionalValidation(props)
          : []),
        () =>
          postcodeValidation(
            props,
            props.selectedCountry,
            ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
              .POSTCODE
          ),
        () =>
          postcodeValidation(
            props,
            props.selectedReturnCountry,
            ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE
          ),
        () => {
          const country = props.countryList.find(
            ({ countryKey }) =>
              countryKey ===
              getValue(
                props.createShipmentValues,
                ShipmentEntity.INVOICE.EXPORTER_DETAILS.ADDRESS.COUNTRY_CODE,
                ""
              )
          );
          return postcodeValidation(
            props,
            country,
            ShipmentEntity.INVOICE.EXPORTER_DETAILS.ADDRESS.POSTCODE
          );
        },
        () => {
          const country = props.countryList.find(
            ({ countryKey }) =>
              countryKey ===
              getValue(
                props.createShipmentValues,
                ShipmentEntity.INVOICE.IMPORTER_DETAILS.ADDRESS.COUNTRY_CODE,
                ""
              )
          );
          return postcodeValidation(
            props,
            country,
            ShipmentEntity.INVOICE.IMPORTER_DETAILS.ADDRESS.POSTCODE
          );
        },
        () =>
          countryValidation(
            props,
            ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
              .COUNTRY_CODE
          ),
        () =>
          countryValidation(
            props,
            ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
              .COUNTRY_CODE
          ),
        () =>
          invoiceImporterCountryValidation(
            props,
            ShipmentEntity.INVOICE.IMPORTER_DETAILS.ADDRESS.COUNTRY_CODE
          ),
        () => parcelCountryValidation(props),
        () => validateShipmentDate(props, ShipmentEntity.SHIPMENT_DATE),
        () => invoiceExporterUkimsValidation(props),
        () => invoiceImporterUkimsValidation(props),
        () => invoiceImporterEoriValidation(props),
        () => parcelValidation(props),
        joiValidationErrors =>
          returnTotalWeightValidation(props, joiValidationErrors),
        joiValidationErrors =>
          outboundTotalWeightValidation(props, joiValidationErrors),
        joiValidationErrors => submissionErrorFirst(props, joiValidationErrors),
      ])(values, props),
    onSubmitFail: (errors, dispatch, _, props) => {
      const mappedErrors = getDeepKeys(errors);
      props?.notifier.scrollToError(mappedErrors);

      if (
        !props.parcelsErrorsBannerId &&
        (props.isEditProductFormActive || !props.isParcelsDataValid)
      ) {
        props.snackbar.showWarning({
          message: props.allowedFields[
            ProductDescriptionsEntity.PRODUCT_DESCRIPTION
          ]
            ? formatMessage(S.$_MUST_BE_ENTERED, S.PRODUCT_DESCRIPTION)
            : formatMessage(
                S.PLEASE_SAVE_$_BEFORE_PRINTING,
                `${getPackageContentOrder(props)}. ${S.CUSTOMS}`
              ),
        });

        if (
          props.allowedFields[ProductDescriptionsEntity.PRODUCT_DESCRIPTION]
        ) {
          dispatch(
            touch(
              PRODUCT_DESCRIPTIONS_FORM,
              ProductDescriptionsEntity.PRODUCT_DESCRIPTION
            )
          );
        }
      }

      if (
        props.allowedFields[ProductDescriptionsEntity.PRODUCT_DESCRIPTION] &&
        mappedErrors.includes(ProductDescriptionsEntity.PRODUCT_DESCRIPTION)
      ) {
        props.snackbar.showWarning({
          message: formatMessage(
            S.PLEASE_SAVE_$_BEFORE_PRINTING,
            S.PRODUCT_DESCRIPTIONS
          ),
        });
      }
    },
  }),
  withPostcodeIssue,
  withPostcodeAutocomplete(OUTBOUND_CONSIGNMENT),
  withAddressBookAutocomplete({
    formSection: OUTBOUND_CONSIGNMENT,
    searchFormName: DELIVERY_DETAILS_SEARCH_FORM,
    addressBookType: S.ADDRESS_BOOK_TYPES.DELIVERY,
  }),
  withShipmentDate,
  withClearData,
  withCreateShipmentAnalytics, // TODO: REFACTORING
  withHandlers({
    fetchCountriesProfilesCurrenciesExportReasonsUniqueSenderRef:
      ({
        fetchCountries,
        fetchProfiles,
        fetchCurrencies,
        fetchExportReasons,
        generateUniqueSenderRef1,
      }) =>
      async ({
        isAvailableInvoice,
        shouldGenerateUniqueSenderRef1,
        shipmentCountryCode,
        shipmentProfileCode,
      } = {}) => {
        let uniqueSenderRef1;
        const [
          { data: countries },
          { data: profiles },
          { data: currencies },
          { data: exportReasons },
        ] = await Promise.all([
          fetchCountries(),
          fetchProfiles(),
          fetchCurrencies(),
          isAvailableInvoice && fetchExportReasons(),
        ]).then(results =>
          results.map(result => ({
            data: result?.data || [],
          }))
        );

        // NOTE: should generate not for all pages
        if (shouldGenerateUniqueSenderRef1) {
          uniqueSenderRef1 = await generateUniqueSenderRef1();
        }

        const deliveryCountry =
          countries.find(
            ({ countryKey }) => countryKey === shipmentCountryCode
          ) || {};
        const activeProfile =
          profiles.find(
            ({ profileCode }) => profileCode === shipmentProfileCode
          ) || profiles[0];

        return {
          countries,
          profiles,
          currencies,
          exportReasons,
          uniqueSenderRef1,
          deliveryCountry,
          activeProfile,
        };
      },
    fetchAndProcessServices:
      ({
        fetchOutboundServices,
        outboundMapErrors,
        snackbar,
        fetchInboundServices,
        setOutboundQuery,
        setInboundQuery,
        inboundMapErrors,
        customerPrefs,
        authUser,
        preferences,
        createShipmentValues,
        userCustomerAddress,
        customer,
        countries,
      }) =>
      async ({
        shipment,
        profile,
        selectedCountry,
        setupDefaultService = true,
      }) => {
        const isReturnShipmentType =
          ShipmentModels.isSwapItOrReverseItShipmentType(shipment.shipmentType);

        let outboundErrors = {};
        let inboundErrors = {};
        let outboundNetworks = [];
        let inboundNetworks = [];
        let selectedOutboundNetwork;

        let isReturnShipmentTypeProhibited =
          !isReturnShipmentType ||
          ShipmentModels.isReturnShipmentTypeProhibited(
            customerPrefs,
            shipment.shipmentType
          );
        let inboundQuery = {};
        let outboundQuery = ShipmentModels.getNetworkQueryFromShipment(
          {
            ...shipment,
            shipmentType: isReturnShipmentTypeProhibited
              ? SHIPMENT_TYPES.NO_TYPE
              : shipment.shipmentType,
          },
          profile.profileCode,
          OUTBOUND_CONSIGNMENT,
          // NOTE: redefine collectionDetails because for loading Copy Shipment profile from props is incorrect
          ShipmentModels.getCollectionDetails(
            createShipmentValues,
            profile,
            userCustomerAddress,
            customer,
            countries
          )
        );

        const countryCode = getValue(
          shipment,
          ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
            .COUNTRY_CODE,
          ""
        );
        const postcode = getValue(
          shipment,
          ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE,
          ""
        );

        if (
          SettingsModels.isNewVersion(customer.shippingVersion) &&
          AddressModels.isNiShipment(countryCode, postcode)
        ) {
          outboundQuery = {
            isBusiness: get(shipment, "invoice.importerDetails.isBusiness"),
            atRisk: get(shipment, "invoice.importerDetails.atRisk"),
            ...outboundQuery,
          };
        }

        try {
          const { data } = await fetchOutboundServices(outboundQuery);
          outboundNetworks = ServiceModels.getServicesForUser(
            authUser.user,
            profile,
            data,
            customer,
            shipment,
            true
          );
          selectedOutboundNetwork =
            outboundNetworks?.find(
              service =>
                service.networkKey === shipment.outboundConsignment.networkCode
            ) ||
            (setupDefaultService
              ? ServiceModels.getDefaultOutboundService({
                  services: outboundNetworks,
                  authUser,
                  preferences,
                  profile,
                  createShipmentValues: shipment,
                  selectedCountry,
                })
              : {});

          if (
            !selectedOutboundNetwork?.collectOnDelivery &&
            isReturnShipmentType
          ) {
            isReturnShipmentTypeProhibited = true;
          }
        } catch (error) {
          isReturnShipmentTypeProhibited = true;
          outboundErrors = outboundMapErrors(error);
          const message =
            get(error, "errors[0].message") ||
            formatMessage(
              S.FAILED_TO_FETCH_ERROR_MESSAGE_$,
              S.OUTBOUND_SERVICES
            );
          snackbar.showAlert({
            message,
            displayTime: SHOW_ALERT_DISPLAY_TIME,
          });
        } finally {
          try {
            if (
              shipment.inboundConsignment &&
              !isReturnShipmentTypeProhibited
            ) {
              inboundQuery = ShipmentModels.getNetworkQueryFromShipment(
                shipment,
                profile.profileCode,
                INBOUND_CONSIGNMENT
              );
              const { data } = await fetchInboundServices(inboundQuery);
              inboundNetworks = getServicesForUser(
                authUser.user,
                profile,
                data,
                customer,
                shipment
              );
            }
          } catch (error) {
            inboundErrors = inboundMapErrors(error);
            const message =
              get(error, "errors[0].message") ||
              formatMessage(
                S.FAILED_TO_FETCH_ERROR_MESSAGE_$,
                S.INBOUND_SERVICES
              );
            snackbar.showAlert({
              message,
              displayTime: SHOW_ALERT_DISPLAY_TIME,
            });
          } finally {
            setOutboundQuery(outboundQuery);
            shipment.inboundConsignment &&
              !isReturnShipmentTypeProhibited &&
              setInboundQuery(inboundQuery);
          }
        }

        return {
          outboundNetworks,
          inboundNetworks,
          selectedOutboundNetwork,
          outboundErrors,
          inboundErrors,
        };
      },
    runCheckPostcodeIssues:
      ({ checkPostcodeIssue, currentValues, collectionDetails }) =>
      async () => {
        const collectionPostcode = get(collectionDetails, "address.postcode");

        await checkPostcodeIssue(
          collectionPostcode,
          S.COLLECTION,
          issue =>
            !issue.allowCollection &&
            issue?.postcodeIssue?.[0]?.postcodeDelay !== 0
        );

        checkPostcodeIssue(
          get(
            currentValues,
            ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
              .POSTCODE
          ),
          S.DELIVERY,
          issue => issue?.postcodeIssue?.[0]?.postcodeDelay !== 0
        );
      },
    initializeShipment:
      ({
        dispatch,
        pageConfig,
        touchShipmentForm,
        stopSubmitShipmentForm,
        customer,
      }) =>
      ({
        shipment,
        selectedOutboundNetwork,
        inboundNetworks,
        outboundErrors,
        inboundErrors,
        initialValues,
      }) => {
        dispatch(
          ReferenceActions.setActiveOutboundService(selectedOutboundNetwork)
        );

        if (!isEmpty(inboundNetworks)) {
          const inboundNetwork = inboundNetworks?.find(
            service =>
              service.networkKey ===
              initialValues.inboundConsignment.networkCode
          );
          dispatch(ReferenceActions.setActiveInboundService(inboundNetwork));
        }

        dispatch(initializeForm(pageConfig.formName, initialValues));

        // TODO: refactor it related to the new change service handler
        // update because onGenerateCustomsDataChange set unnecessary default values before
        if (
          ServiceModels.isFlagNeedles(selectedOutboundNetwork, "taxRequired")
        ) {
          dispatch(
            ShipmentActions.resetInvoiceAdditionalDataRequirements(pageConfig)
          );
        }
        if (!ServiceModels.isVisibleFdaNumber(selectedOutboundNetwork)) {
          dispatch(
            change(
              pageConfig.formName,
              ShipmentEntity.INVOICE.EXPORTER_DETAILS.FDA_NUMBER,
              undefined
            )
          );
        }

        // update because handleTaxIdAndGst set unnecessary default values before
        if (
          !ServiceModels.isVisibleDestinationTaxIdRegNo(
            selectedOutboundNetwork,
            initialValues.generateCustomsData,
            customer
          )
        ) {
          dispatch(ShipmentActions.resetAdditionalDataRequirements(pageConfig));
        }

        // update because populateDefaultNetwork set unnecessary default values before
        if (isEmpty(initialValues.invoice)) {
          dispatch(resetSection(pageConfig.formName, INVOICE));
        }

        if (
          !shipment.isVoided &&
          (!isEmpty(outboundErrors) || !isEmpty(inboundErrors))
        ) {
          touchShipmentForm({
            ...outboundErrors,
            ...inboundErrors,
          });
          stopSubmitShipmentForm({
            ...outboundErrors,
            ...inboundErrors,
          });
        }
      },
    onImporterTypeChange:
      ({
        dispatch,
        pageConfig,
        setOutboundQuery,
        createShipmentValues,
        profile,
        customer,
        countries,
        userCustomerAddress,
        user,
        selectedAddressBook,
      }) =>
      async (isBusiness, previousValue) => {
        // NOTE: must set activeField to trigger fetch services
        dispatch(
          autofill(
            pageConfig.formName,
            "activeField",
            ShipmentEntity.INVOICE.IMPORTER_DETAILS.IS_BUSINESS
          )
        );
        const countryCode = getValue(
          createShipmentValues,
          ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
            .COUNTRY_CODE,
          ""
        );
        const postcode = getValue(
          createShipmentValues,
          ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE,
          ""
        );
        const atRisk = isBusiness
          ? getValue(selectedAddressBook, Fields.AT_RISK, false)
          : false;

        // case: change atRisk then select new addressBook
        if (
          SettingsModels.isNewVersion(customer.shippingVersion) &&
          AddressModels.isNiShipment(countryCode, postcode)
        ) {
          if (isBusiness === previousValue) {
            return;
          }
          // NOTE: fetch requiredFields first, then redefine invoice
          await dispatch(
            ReferenceActions.fetchNiShipmentRequiredFields({
              countryCode,
              postcode,
              isBusiness,
              atRisk,
              businessUnit: user.businessId,
            })
          );
          // NOTE: must save outboundQuery for properly reFetching services
          setOutboundQuery({
            ...ShipmentModels.getQueryForServices(
              createShipmentValues,
              OutboundServicesRelatedFields,
              ShipmentModels.getCollectionDetails(
                createShipmentValues,
                profile,
                userCustomerAddress,
                customer,
                countries
              ),
              profile?.profileCode,
              OUTBOUND_CONSIGNMENT,
              customer
            ),
            isBusiness,
            atRisk,
          });
        }

        dispatch(
          ShipmentActions.onImporterTypeChange(pageConfig, isBusiness, atRisk)
        );
        dispatch(autofill(pageConfig.formName, "activeField", undefined));
      },
    onAtRiskChange:
      ({
        dispatch,
        pageConfig,
        setOutboundQuery,
        createShipmentValues,
        profile,
        customer,
        countries,
        userCustomerAddress,
        user,
      }) =>
      async atRisk => {
        // NOTE: must set activeField to trigger fetch services
        dispatch(
          autofill(
            pageConfig.formName,
            "activeField",
            ShipmentEntity.INVOICE.IMPORTER_DETAILS.AT_RISK
          )
        );
        const countryCode = getValue(
          createShipmentValues,
          ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
            .COUNTRY_CODE,
          ""
        );
        const postcode = getValue(
          createShipmentValues,
          ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE,
          ""
        );
        const isBusiness = getValue(
          createShipmentValues,
          ShipmentEntity.INVOICE.IMPORTER_DETAILS.IS_BUSINESS,
          false
        );

        // case: change atRisk then select new addressBook
        if (AddressModels.isNiShipment(countryCode, postcode)) {
          // NOTE: fetch requiredFields first, then redefine invoice
          await dispatch(
            ReferenceActions.fetchNiShipmentRequiredFields({
              countryCode,
              postcode,
              isBusiness,
              atRisk,
              businessUnit: user.businessId,
            })
          );
          // NOTE: must save outboundQuery for properly reFetching services
          setOutboundQuery({
            ...ShipmentModels.getQueryForServices(
              createShipmentValues,
              OutboundServicesRelatedFields,
              ShipmentModels.getCollectionDetails(
                createShipmentValues,
                profile,
                userCustomerAddress,
                customer,
                countries
              ),
              profile?.profileCode,
              OUTBOUND_CONSIGNMENT,
              customer
            ),
            atRisk,
          });
        }

        dispatch(ShipmentActions.onAtRiskChange(pageConfig, atRisk));
        dispatch(autofill(pageConfig.formName, "activeField", undefined));
      },
  }),

  lifecycle({
    componentDidUpdate(prevProps) {
      const {
        dispatch,
        selectedService,
        customsFieldsFlags,
        parcelsTotalValue,
        populateDefaultCustomsValue,
        checkPostcodeIssue,
        createShipmentValues,
        activeField,
        pageConfig,
        allowedFields,
        setDefaultDeliveryDescription,
      } = this.props;
      const isVisibleTaxId =
        allowedFields[
          ShipmentEntity.OUTBOUND_CONSIGNMENT.DESTINATION_TAX_ID_REG_NO
        ];
      const prevIsVisibleTaxId =
        prevProps.allowedFields[
          ShipmentEntity.OUTBOUND_CONSIGNMENT.DESTINATION_TAX_ID_REG_NO
        ];

      // NOTE: do it here because it's related service, prevService, generateCustomsData and prevGenerateCustomsData
      if (
        !isEmpty(
          omit(customsFieldsFlags, [Fields.EXPORT_REASON, Fields.SHIPPING_COST])
        ) &&
        isEmpty(
          omit(prevProps.customsFieldsFlags, [
            Fields.EXPORT_REASON,
            Fields.SHIPPING_COST,
          ])
        )
      ) {
        dispatch(setDefaultCurrency(pageConfig));
      }

      if (
        isEmpty(
          omit(customsFieldsFlags, [Fields.EXPORT_REASON, Fields.SHIPPING_COST])
        ) &&
        !isEmpty(
          omit(prevProps.customsFieldsFlags, [
            Fields.EXPORT_REASON,
            Fields.SHIPPING_COST,
          ])
        )
      ) {
        dispatch(clearCustoms(pageConfig.formName));
      }

      if (
        customsFieldsFlags.customsValueDisabled &&
        parcelsTotalValue !== prevProps.parcelsTotalValue
      ) {
        populateDefaultCustomsValue(parcelsTotalValue);
      }

      if (
        customsFieldsFlags.customsValueDisabled &&
        !prevProps.customsFieldsFlags.customsValueDisabled
      ) {
        parcelsTotalValue !== S.DEFAULT_ZERO_VALUE
          ? populateDefaultCustomsValue(parcelsTotalValue)
          : dispatch(
              resetSection(
                pageConfig.formName,
                ShipmentEntity.OUTBOUND_CONSIGNMENT.CUSTOMS_VALUE
              )
            );
      }

      // NOTE: do it here because it's related service, prevService, generateCustomsData and prevGenerateCustomsData
      // manage only manual changing generateCustomsData, dynamic changing is managed in the handleTaxIdAndGst
      if (
        selectedService &&
        prevProps.selectedService &&
        isVisibleTaxId !== prevIsVisibleTaxId
      ) {
        dispatch(
          ShipmentActions.setupAdditionalDataRequirements(
            isVisibleTaxId,
            pageConfig
          )
        );
      }

      if (
        prevProps.activeField ===
          ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
            .POSTCODE &&
        activeField !==
          ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE
      ) {
        const postcode = getValue(
          createShipmentValues,
          ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE
        )?.toUpperCase();

        if (this.props.prevPostcodeIssue !== postcode) {
          checkPostcodeIssue(
            postcode,
            S.DELIVERY,
            issue => issue?.postcodeIssue?.[0]?.postcodeDelay !== 0
          );
          this.props.setPrevPostcodeIssue(postcode);
        }
      }

      // NOTE: do it here because it's related service, prevService, generateCustomsData and prevGenerateCustomsData
      if (
        !prevProps.allowedFields[
          ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DESCRIPTION
        ] &&
        allowedFields[ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DESCRIPTION]
      ) {
        setDefaultDeliveryDescription();
      }

      if (
        prevProps.allowedFields[
          ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DESCRIPTION
        ] &&
        !allowedFields[ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DESCRIPTION]
      ) {
        dispatch(
          resetSection(
            pageConfig.formName,
            ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DESCRIPTION
          )
        );
      }
    },
    componentWillUnmount() {
      this.props.prompt.onClose();
    },
  })
);
